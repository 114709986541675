.btn-light {
    background: #F5F5F6;
    color: #201747;
    border: 1px solid #F5F5F6;
}

.nav-link {color: #fff !important;}

.bordered-box {
    padding: 10px 20px;
    text-align: start;
    border: #e9e9e9 2px solid;
    border-radius: 8px;
    margin-bottom: 12px;
    &:hover {border: #f5b335 2px solid;background-color: #fff9ee}
    h1 {font-size: 16px;font-weight: 800}
    p {font-size: 12px}
}

.scroller-area {
    overflow: auto !important;
    &::-webkit-scrollbar {width: 10px}
    &::-webkit-scrollbar-thumb {background: #f5b335;border-radius: 5px}
    &::-webkit-scrollbar-thumb:hover {background: #dc9207;border-radius: 5px}
}