@import "libs";

body{
    // this for ltr style
    &.ltr {
        font-family: 'Inter', 'Cairo' !important;
        font-weight: 500;
        font-style: normal;
        $right : "right";
        $left : "left";
        $rtl: false;
        @import "shared";
    }
    // this for rtl style
    &.rtl {
        $right : "left";
        $left : "right";
        $rtl: true;
        
        direction: rtl;
        font-family: 'Cairo' !important;
        @media (min-width: 768px) {
            .offset-md-3 {
                margin-right: 25%;
                margin-left: 0% !important;
            }
        }
        @import "shared";
    }    
    
}

@import "typography";

