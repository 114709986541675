
header {
    background-color: #201747;
    .navbar {
        min-height: 80px;
        .navbar-nav {
            .nav-link {}
        }
    }
}

#errorModal {
    .modal-content {
        
        padding: 40px;
        box-shadow: 0px 12px 24px rgba(44, 41, 38, 0.02);
        border-radius: 16px;
        border: none;
        .modal-body {
            text-align: center;
            padding: 1rem 0;
            img {margin-bottom: 1.5rem;}
        }

    }
    .modal-footer {
        padding: 1rem 0 0 0;
        justify-content: space-between;
        border: 0;
        .btn { width: 45%; padding: 14px 0; }
    }
}
.custom-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2.5rem;
    width: 30%;
    border-radius: 0.5rem;
    text-align: center;
    @include media-breakpoint-down(md) { 
        width: 90%;
    }
    a {
        color: $yellow;
    }

    .close-button {
        width: 1.5rem;
        cursor: pointer;
        border-radius: 50%;
        background-color: $gray-04;
        &:hover {
            background-color: $gray-32;
        }
    }
    .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $red;
        margin-top: 2rem;
    }
    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
    .modal-footer {
        padding: 2.5rem 0 0.5rem 0;
        justify-content: space-around;
        .btn {
            padding: 1rem 3.5rem;
            font-size: 16px;
            line-height: 28px;
            font-weight: 500;

            &.btn-secondary {
                background: $gray-04;
                border-color: $gray-04;
                color: #201747;
                &:hover {opacity: 0.7;}
            }
        }
    }
}

.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.content-area {
    margin: 35px auto;
}


.box_container {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 40px;

    .box_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        h2 {
            font-size: 24px;
            margin: 0;
        }

        p {
            font-weight: 300;
            padding: 22px 0px 14px;
            border-bottom: 1px solid #EDEEEF;
            color: #2C2926;

            strong {
                font-weight: 500
            }
        }
    }
}

.cart {
    @include media-breakpoint-down(lg) {
        margin-top: 30px;
    }

    .cart-container {
        p {
            color: #6B727C;
        }

        .cart-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 20px auto;

            img {
                width: 70px;
                height: 70px;
                border: 1px solid #EDEEEF;
                border-radius: 10px;
            }

            .product-details {
                width: inherit;
                margin: auto 20px;

                .product-name {
                    font-size: 14px;
                    color: #2C2926;
                }

                .product-price {
                    color: #464E5B;
                    font-size: 12px;
                    font-weight: 300;
                }
            }
        }
    }
}

.form {
    .input {
        margin-bottom: 1rem;

        label {
            color: #6B727C;
            font-size: 14px;
        }

        input {
            padding: 20px;
            background: #F6F6F7;
            border-radius: 8px;
            border-color: #F6F6F7;
            font-size: 14px;

            &::placeholder {
                color: $gray-32;
            }

            &:focus {
                color: #212529;
                border-bottom: solid 1px #425CC7;
                outline: 0;
                box-shadow: none;
            }
        }

        &.has-error {
            .invalid-feedback {
                display: block !important;
            }

            input {
                background-image: url(../images/error_icon.svg);

                @if $rtl ==false {
                    background-position-x: 96%;
                }

                background-repeat: no-repeat;
                background-position-y: center;
                padding-inline-end: 45px;
            }
        }

        .form-text.hint {
            padding-inline-start: 20px;
            background-image: url(../images/text-hint.svg);
            background-repeat: no-repeat;
            background-position-y: center;
            font-weight: 300;
        }

        .phone {
            .country-flag {
                background: #f6f6f7;
                border-radius: 8px;
                padding: 15px;
                display: flex;
                align-items: center;
                margin-inline-end: 8px;
                flex-direction: row;

                img {
                    height: 20px;
                    margin-inline-end: 10px;
                }

                span {
                    color: #6B727C;
                }
            }
        }

        .input-with-tag {
            display: flex;
            align-items: center;
            background: #F6F6F7;
            padding: 0 19px;
            border-radius: 8px;
            position: relative;
            height: 60px;

            span {
                z-index: 9;
                font-size: 14px;
                color: $gray-64;
            }

            input {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                padding-left: 60px;
            }
        }
    }

    .form-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            min-height: 115px;
        }
    }
}

.my-table {
    margin-top: 20px;
    p {margin: 0;}
    
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        vertical-align: middle;
        text-align: center;
        padding: 20px 0;
        &:first-child {
            border-top-#{$left}-radius: 15px;
            border-bottom-#{$left}-radius: 15px;
        }
        &:last-child {
            border-top-#{$right}-radius: 15px;
            border-bottom-#{$right}-radius: 15px;
        }
    }
    tr:last-child {
        border-bottom: 0px solid white;
    }
    th {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        background-color: #FAFBFC;
        border-bottom: 0;
        align-items: center;
        letter-spacing: 1px;
        text-transform: uppercase;
       padding: 18px 0;
    }

    td {
        font-weight: 400;
        font-size: 14px;

        .gray {
            color: #808080;
        }

        .btn-link {
            color: $yellow;
            text-decoration: none;
        }

        .btn-my-dropdown {
            @extend .btn-outline-light;
            background: #FAFBFC;
            border-radius: 15px !important;
            border: 0;
            color: $yellow;
            font-size: 14px;
            line-height: 14px;

            &:hover {
                color: black;
            }
            
            &:focus {
                box-shadow: none;
            }
            &.VERIFIED {cursor: auto;background: $green; color: $white}
            &.REJECTED {cursor: auto;background: $red; color: $white}
        }

        .dropdown-menu {
            padding: 10px 10px;
            border: 2px solid $gray-70;

            li {
                border-bottom: 1px solid $gray-70;
                padding-bottom: 5px;
                margin-bottom: 5px;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}
.table-header {
    display: flex;
    .search-input {
        background: #FAFBFC;
        border-radius: 15px;
        overflow: hidden;
        padding: 5px;
        margin: 0 25px;
        span,
        input,
        input:focus {
            background: #FAFBFC;
            border: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 29px;
        }
        @include media-breakpoint-down(md) { 
            margin: 0;
         }
    }
    
    .btn {
        background: #F5F5F6;
        border-radius: 15px;
        padding: 9px 12px;
    }
    .btn-warning {
        background: #F5B335;
        border-radius: 15px;
        padding: 9px 12px;
    }
    @include media-breakpoint-down(md) { 
        .refresh-btn {display: none;}
    }
}

.table-footer {
    background: #FAFBFC;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(md) { 
        flex-direction: column;
    }
    .results-count {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        color: #B0B7C3;
        text-transform: capitalize;
    }
    .results-pagination {
        .pagination {
            margin: 0;
            @include media-breakpoint-down(md) { 
                padding: 0;
            }
            .prev {
                @if $rtl == false {
                    transform: rotate(180deg);
                }
            }
            .next {
                @if $rtl {
                    transform: rotate(180deg);
                }
            }
            .prev, .next {background: #F5B335; margin-top: 5px;}
            
            button {
                border: 0;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 13px 16px;
                font-size: 16px;
                color: #B0B7C3;
                text-decoration: none;
                &:disabled {
                    background: #F3F3F3;
                    img {opacity: 0.2;}
                }
            }
            .active {button {color: #F5B335}}
        }
    }
}

.offcanvas-filter {
    border-radius: 10px 0 0 10px;
    .offcanvas-header {
        padding: 28px 34px;
        border-bottom: 1px solid #B0B7C3;
    }
    .offcanvas-body {
        select, input {
            background: #FFFFFF;
            border: 1px solid #E7E5EA;
            border-radius: 8px;
            padding: 12px 19px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
            color: #8F8F8F;
        }
    }
    .offcanvas-footer {
        .results {
            text-align: center;
            border-bottom: #d3d3d3 1px solid;
            border-top: #d3d3d3 1px solid;
            padding: 25px 0;
        }
        .main-buttons {
            padding: 20px;
            display: flex;
            justify-content: space-between;
            button {
                border-radius: 15px;
                padding: 11px 25px;
            }
        }
    }
}

.btn-submit {
    width: 100%;
    background: #F5B335;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    padding: 12px;    
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url("../images/login_bg.svg") #201747 right no-repeat;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .box_container {
        width: fit-content;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
        border-radius: 26px;
        padding: 50px 40px;
        margin: 50px;
        min-width: 25%;
        .form-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            padding-bottom: 5px;
        }
        .form-control {
            background: #FFFFFF;
            border: 1px solid #E7E5EA;
            border-radius: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            padding:  10px 15px ;
        }
    }
}